import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Terms from '~/components/pages/Terms'
import SEO from '~/utils/seo'

export default function TermsPage({ data, location }: PageProps<GatsbyTypes.TermsQuery>) {
    return (
        <>
            <SEO
                title='利用規約 | Onsen*(オンセン)'
                description='Onsen*関連サービス並びにOnsen*Storeの利用規約をご確認ください。'
                robots='all'
                url={location.href}
            />
            <Terms data={data} />
        </>
    )
}

export const query = graphql`
	query Terms {
		 microcmsTerms {
		    content
		 }
	}
`
